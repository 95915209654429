/** @jsx jsx */
import { jsx, Box, Container, Heading, Flex, Text } from "theme-ui"
import PropTypes from "prop-types"

const SectionWhoWeAre = () => {
  return (
    <Box>
      <Container>
        <Flex
          sx={{
            alignItems: "center",
            flexDirection: "column",
          }}>
          {/* offset height of navbar to scroll to accurately */}
          <span
            id="who-we-are"
            sx={{
              top: (t) => `-${t.space[5] * 1.8}px`,
              position: "relative",
              visibility: "hidden",
            }}
          />
          <Heading as="h2" variant="text.section_headline" pt={4}>
            Who we are
          </Heading>
          <Text
            py={4}
            sx={{
              textAlign: "center",
            }}>
            We are a team of experienced designers and developers, who thrive to
            build top quartile and unique products. Helping business owners,
            keep it simple, achieve the goal at a competitive cost and taking
            ownership with sustainability mindset are our core values.
          </Text>
        </Flex>
      </Container>
    </Box>
  )
}

SectionWhoWeAre.propTypes = {
  siteTitle: PropTypes.string,
}

SectionWhoWeAre.defaultProps = {
  siteTitle: ``,
}

export default SectionWhoWeAre
