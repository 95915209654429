/** @jsx jsx */
import { jsx, Box, Container, Heading, Flex } from "theme-ui"
import PropTypes from "prop-types"
import CallToAction from "./call-to-action"
import Header from "./header"

const SectionIntro = ({ siteTitle }) => {
  return (
    <Box
      bg="dark"
      sx={{
        height: ["95vh", "100vh"],
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: ["95vh", "100vh"],
          pb: 5,
          backgroundSize: ["98% auto", null, "98% auto", null],
          backgroundImage: [
            `url("./banner-mobile.svg")`,
            `url("./banner-tablet.svg")`,
            `url("./banner.svg")`,
            null,
          ],
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 100%",
        }}>
        <Header />
        {/* offset the height of header, which is overlaid */}
        <Box mt={6} />
        <Container
          sx={{
            flex: 1,
          }}>
          <Flex
            sx={{
              height: "100%",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "space-around",
            }}>
            <Box
              sx={{
                alignSelf: "center",
              }}>
              <Heading
                as="h1"
                variant="text.ghost"
                sx={{
                  position: "relative",
                  zIndex: 2,
                  top: 0,
                  left: 0,
                  fontSize: [
                    (t) => `${t.fontSizes[8] * 0.9}px`,
                    (t) => `${t.fontSizes[9] * 0.8}px`,
                    9,
                    null,
                  ],
                  textAlign: "center",
                  color: "muted",
                  opacity: "7%",
                }}>
                {siteTitle}
              </Heading>
              <Heading
                as="h2"
                variant="text.section_crosshead"
                color="textHighlight"
                sx={{
                  position: "relative",
                  zIndex: 3,
                  top: (t) => [
                    `-${t.space[5] * 1.5}px`,
                    `-${t.space[6] * 1}px`,
                    `-${t.space[6] * 1.3}px`,
                    null,
                  ],
                  fontSize: [5, 6, 7, null],
                  wordWrap: "break-word",
                  lineHeight: "body",
                  textAlign: "center",
                  left: 0,
                }}>
                Enabling your next digital leap
              </Heading>
            </Box>
            <Box
              sx={{
                position: ["relative", "absolute", null, null],
                bottom: [null, 0, null, null],
                pb: [5, 5, 5, null],
              }}>
              <CallToAction text="Contact Us" toLoc="#contact-form" />
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

SectionIntro.propTypes = {
  siteTitle: PropTypes.string,
}

SectionIntro.defaultProps = {
  siteTitle: ``,
}

export default SectionIntro
