/** @jsx jsx */
import {
  jsx,
  Heading,
  Container,
  Box,
  Label,
  Input,
  Button,
  Textarea,
  Alert,
  Flex,
  Text,
  Checkbox,
} from "theme-ui"
import { useForm } from "react-hook-form"
import { useState } from "react"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const OptionService = ({ name, label, register }) => {
  return (
    <Label mb={3} sx={{ display: "flex", alignItems: "center" }}>
      <Checkbox name={name} value={label} ref={register} />
      <Text variant="text.body">{label}</Text>
    </Label>
  )
}

const SectionContactForm = () => {
  const { register, errors, handleSubmit } = useForm()
  const [submitState, setSubmitState] = useState("-")
  const onSubmit = (_, e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(new FormData(e.target)).toString(),
    })
      .then((response) => {
        if (response.ok) {
          setSubmitState("success")
        } else {
          setSubmitState("failure")
        }
      })
      .catch(() => setSubmitState("failure"))
  }
  return (
    <Box>
      <Container>
        {/* offset height of navbar to scroll to accurately */}
        <span
          id="contact-form"
          sx={{
            top: (t) => `-${t.space[5] * 1.8}px`,
            position: "relative",
            visibility: "hidden",
          }}
        />
        <Heading
          as="h2"
          variant="text.section_headline"
          sx={{
            textAlign: "center",
          }}>
          Contact Us
        </Heading>
        <Box
          as="form"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="contact"
          onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <Label
            py={2}
            sx={{ fontWeight: "bold", fontSize: [3, 4, 4, null] }}
            htmlFor="name">
            Name
          </Label>
          <Input
            name="name"
            required="true"
            mb={3}
            ref={register({ required: true })}
          />
          {errors.name && <Alert mb={2}>Please enter your name</Alert>}
          <Label
            py={2}
            sx={{ fontWeight: "bold", fontSize: [3, 4, 4, null] }}
            htmlFor="phone">
            Phone Number
          </Label>
          <Input
            name="phone"
            mb={3}
            ref={register({
              required: false,
              pattern: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/g,
            })}
          />
          {errors.phone && (
            <Alert mb={2}>Please enter a valid phone number</Alert>
          )}
          <Label
            py={2}
            sx={{ fontWeight: "bold", fontSize: [3, 4, 4, null] }}
            htmlFor="email">
            E-mail
          </Label>
          <Input
            type="email"
            name="email"
            required="true"
            mb={3}
            ref={register({
              required: true,
              pattern: /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g,
            })}
          />
          {errors.email && <Alert mb={2}>Please enter your E-mail ID</Alert>}
          <Label py={2} sx={{ fontWeight: "bold", fontSize: [3, 4, 4, null] }}>
            Services you're interested in
          </Label>
          <Flex
            mb={3}
            sx={{
              flexDirection: ["column", "row"],
            }}>
            <Box
              sx={{
                flexGrow: "1",
              }}>
              <OptionService
                name="service_1"
                label="Website Design &amp; Development"
                register={register}
              />
              <OptionService
                name="service_2"
                label="Website Revamp &amp; maintenance"
                register={register}
              />
            </Box>
            <Box
              sx={{
                flexGrow: "1",
              }}>
              <OptionService
                name="service_3"
                label="Social media &amp; Go-to-market"
                register={register}
              />
              <OptionService
                name="service_4"
                label="Branding &amp; Graphic Design"
                register={register}
              />
            </Box>
          </Flex>
          {(errors.service_webdesign ||
            errors.service_revamp ||
            errors.service_gtm ||
            errors.service_branding) && (
            <Alert mb={2}>Please select atleast one service</Alert>
          )}
          <Label
            py={2}
            sx={{ fontWeight: "bold", fontSize: [3, 4, 4, null] }}
            htmlFor="message">
            Quick Message
          </Label>
          <Textarea
            name="message"
            rows="6"
            mb={3}
            ref={register({ maxLength: 3000 })}
          />
          {errors.message && (
            <Alert mb={2}>
              Please limit your message within 3000 characters
            </Alert>
          )}
          <Button variant="button">Submit</Button>
          {submitState === "success" && (
            <Alert my={3} bg="#1abc9c">
              Thank you for contacting us. We'll reach you back soon!
            </Alert>
          )}
          {submitState === "failure" && (
            <Alert my={3} bg="#e74c3c">
              Oops! There seems to be an error. Please contact us via the E-mail
              or Phone below
            </Alert>
          )}
        </Box>
      </Container>
    </Box>
  )
}

SectionContactForm.propTypes = {}

SectionContactForm.defaultProps = {}

export default SectionContactForm
