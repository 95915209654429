/** @jsx jsx */
import {
  jsx,
  Box,
  Container,
  Heading,
  Text,
  Flex,
  Link,
  NavLink,
} from "theme-ui"
import { alpha } from "@theme-ui/color"
import EutactLogo from "../assets/svg/eutact-logo-mono.inline.svg"
import FacebookLogo from "../assets/svg/facebook.inline.svg"
import InstagramLogo from "../assets/svg/instagram.inline.svg"
import TwitterLogo from "../assets/svg/twitter.inline.svg"
import YoutubeLogo from "../assets/svg/youtube.inline.svg"

const OfficeLocation = ({ place, country }) => {
  return (
    <Box
      bg="background"
      p={4}
      m={3}
      sx={{
        display: "inline-block",
        borderRadius: (t) => `${t.space[3]}px`,
        boxShadow: (t) => `${t.space[1]}px ${t.space[1]}px 20px #421EC052`,
      }}>
      <Heading
        as="h4"
        variant="text.body"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
        }}>
        {place}
      </Heading>
      <Text
        variant="text.body"
        sx={{
          textAlign: "center",
        }}>
        {country}
      </Text>
    </Box>
  )
}

const SocialLink = ({ href, label, logo }) => {
  return (
    <Link href={href} rel="noreferrer" target="_blank" aria-label={label} p={3}>
      {logo}
    </Link>
  )
}

const SectionContact = () => {
  return (
    <Box bg="highlight">
      <Container>
        <Flex
          pt={4}
          sx={{
            flexDirection: ["column", null, "row", null],
            justifyContent: "space-around",
            alignItems: "center",
          }}>
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Flex
              pt={3}
              sx={{
                justifyContent: "center",
              }}>
              <EutactLogo
                sx={{
                  color: alpha("text", 0.2),
                  width: (t) => `${t.space[6] * 1.4}px`,
                }}
              />
            </Flex>
            <Flex
              sx={{
                mt: "4",
                flexDirection: "row",
                justifyContent: "center",
              }}>
              <SocialLink
                href="https://www.facebook.com/EuTact-Corp-108706297636381"
                label="Eutact at Facebook"
                logo={
                  <FacebookLogo
                    sx={{
                      width: (t) => `${t.space[4]}px`,
                    }}
                  />
                }
              />
              <SocialLink
                href="https://twitter.com/eutact"
                label="Eutact at Twitter"
                logo={
                  <TwitterLogo
                    sx={{
                      width: (t) => `${t.space[4]}px`,
                    }}
                  />
                }
              />
              <SocialLink
                href="https://www.youtube.com/channel/UCIK_pu08Uykdw5hQowN6UIw/"
                label="Eutact at YouTube"
                logo={
                  <YoutubeLogo
                    sx={{
                      width: (t) => `${t.space[4]}px`,
                    }}
                  />
                }
              />
              <SocialLink
                href="https://www.instagram.com/eutactcorp/"
                label="Eutact at Instagram"
                logo={
                  <InstagramLogo
                    sx={{
                      width: (t) => `${t.space[4]}px`,
                    }}
                  />
                }
              />
            </Flex>
            <NavLink
              href="mailto:contact@eutact.com"
              sx={{
                variant: "text.body",
                textTransform: "lowercase",
                color: "text",
              }}>
              contact@eutact.com
            </NavLink>
            <NavLink
              href="tel:+17806652737"
              sx={{
                variant: "text.body",
                textTransform: "lowercase",
                color: "text",
              }}>
              +1 7806652737
            </NavLink>
          </Flex>
          <Box>
            <Heading
              as="h3"
              variant="text.section_crosshead"
              pb={4}
              sx={{
                pt: [5, null, "0px", null],
                textAlign: "center",
              }}>
              Our Offices
            </Heading>
            <Flex
              sx={{
                flexDirection: ["column", "row", null, null],
                justifyContent: "center",
              }}>
              <OfficeLocation place="Edmonton" country="Canada" />
              <OfficeLocation place="Boston" country="USA" />
              <OfficeLocation place="Chennai" country="India" />
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

SectionContact.propTypes = {}

SectionContact.defaultProps = {}

export default SectionContact
