import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionIntro from "../components/section-intro"
import SectionWhoWeAre from "../components/section-who-we-are"
import SectionWhatWeDo from "../components/section-what-we-do"
import SectionContact from "../components/section-contact"
import SectionContactForm from "../components/section-contact-form"

const IndexPage = () => (
  <Layout showHeader={false}>
    <SEO title="Home" />
    <SectionIntro siteTitle="EuTACT" />
    <SectionWhoWeAre />
    <SectionWhatWeDo />
    <SectionContactForm />
    <SectionContact />
  </Layout>
)

export default IndexPage
