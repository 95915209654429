/** @jsx jsx */
import { Button, jsx } from "theme-ui"
import PropTypes from "prop-types"
import { darken } from "@theme-ui/color"

const CallToAction = ({ text, toLoc }) => {
  const scroll = (e) => {
    e.preventDefault()
    document.querySelector("#contact-form").scrollIntoView({
      behavior: "smooth",
    })
  }

  return (
    <Button
      onClick={scroll}
      sx={{
        "variant": "text.button",
        "px": 4,
        "py": 3,
        "display": "inline-block",
        "borderRadius": (t) => `${t.space[4]}px`,
        "backgroundImage": (t) => `linear-gradient(
            to right,
            ${t.colors.primary},
            ${t.colors.secondary}
        )`,
        "&:hover": {
          cursor: "pointer",
          backgroundImage: (t) => `linear-gradient(
            to right,
            ${darken("primary", 0.1)(t)},
            ${darken("secondary", 0.1)(t)}
        )`,
        },
        "&:active": {
          cursor: "pointer",
          backgroundImage: (t) => `linear-gradient(
            to right,
            ${darken("primary", 0.2)(t)},
            ${darken("secondary", 0.2)(t)}
        )`,
        },
      }}>
      {text}
    </Button>
  )
}

CallToAction.propTypes = {
  text: PropTypes.string,
  toLoc: PropTypes.string,
}

CallToAction.defaultProps = {
  text: ``,
  toLoc: `#`,
}

export default CallToAction
