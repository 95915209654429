/** @jsx jsx */
import { jsx, Box, Container, Heading, Flex, Text } from "theme-ui"
import PropTypes from "prop-types"
import DigitalTransformation from "../assets/svg/digital-transformation.inline.svg"
import FreshLook from "../assets/svg/fresh-look.inline.svg"
import YourIdea from "../assets/svg/your-idea.inline.svg"

const SubSection = ({ subSectionTitle, alignedLeft, image, children }) => {
  return (
    <Box>
      <Container>
        <Flex
          sx={{
            justifyContent: [
              "center",
              null,
              `${alignedLeft ? "flex-start" : "flex-end"}`,
              null,
            ],
          }}>
          <Heading
            as="h3"
            variant="text.section_crosshead"
            pt={4}
            sx={{
              wordWrap: "break-word",
              width: ["100%", null, null, "70%"],
              textAlign: [
                "center",
                null,
                null,
                `${alignedLeft ? "left" : "right"}`,
              ],
            }}>
            {subSectionTitle}
          </Heading>
        </Flex>
        <Flex
          sx={{
            pt: 3,
            flexDirection: [
              "column",
              null,
              `${alignedLeft ? "row" : "row-reverse"}`,
              null,
            ],
          }}>
          <Text
            py={4}
            pr={[0, alignedLeft ? 4 : 0, null, null]}
            pl={[0, alignedLeft ? 0 : 4, null, null]}
            sx={{
              flex: 1,
              display: "flex",
              textAlign: [
                "center",
                null,
                `${alignedLeft ? "left" : "right"}`,
                null,
              ],
              alignItems: "center",
            }}>
            {children}
          </Text>
          <Box sx={{ flex: 1 }}>{image}</Box>
        </Flex>
      </Container>
    </Box>
  )
}

SubSection.propTypes = {
  subSectionTitle: PropTypes.string,
  alignedLeft: PropTypes.bool,
  image: PropTypes.node.isRequired,
}

SubSection.defaultProps = {
  subSectionTitle: ``,
  alignedLeft: true,
}

const SectionWhatWeDo = () => {
  return (
    <Box>
      <Container py="0px">
        <Box pt={3}>
          {/* offset height of navbar to scroll to accurately */}
          <span
            id="what-we-do"
            sx={{
              top: (t) => `-${t.space[5] * 1.8}px`,
              position: "relative",
              visibility: "hidden",
            }}
          />
          <Heading
            as="h2"
            variant="text.section_headline"
            sx={{
              textAlign: "center",
            }}>
            What we do
          </Heading>
        </Box>
      </Container>
      <SubSection
        subSectionTitle="Digital Transformation for your business"
        alignedLeft={true}
        image={<DigitalTransformation />}>
        Embracing the digital world is a new mandate to achieve business goals.
        Providing support to build the digital twin of your dream business is
        our passion. We focus on core values that add up to your channel of
        revenue. Starting with branding and logo design, we also set up your
        social media to voice out your brand in the competitive digital world.
      </SubSection>
      <SubSection
        subSectionTitle="Your idea to product a reality and beyond"
        alignedLeft={false}
        image={<YourIdea />}>
        Making your ideas a reality with an omnipresent digital product is what
        we do at EuTact. We collaborate with hand-picked designers and
        developers on board to assure the best craftsmanship for your dream
        startup. We help you refine your idea and benchmark against your
        competitors. 
      </SubSection>
      <SubSection
        subSectionTitle="A fresh look for your existing digital footprint"
        alignedLeft={true}
        image={<FreshLook />}>
        We also take ownership to uplift your existing website and make it user
        friendly. We have experts who do finesse touch and do wonders to fulfil
        your requirements. We also do end-to-end maintenance to promise an
        uninterrupted service to your customers.
      </SubSection>
    </Box>
  )
}

export default SectionWhatWeDo
